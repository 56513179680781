.unseen {
	background: #eef5fa;
}
.notifications {
	padding: 0;
	.card-body {
		display: table;
		width: 100%;
	}
	.card-avatar {
		width: 32px;
		height: 32px;
	}
	dt {
		span {
			font-weight: 400;
			color: @gray;
		}
	}
	.card-body {
		p, dd {
			color: @gray-darker;
		}
	}
}
.follow {
	.btn {
		width: 140px;
	}
	.btn, .followers {
		.pull-left;
		@media (max-width: @screen-xs-max) {
			.text-center;
			width: 50%;
		}
	}
	.followers {
		color: @gray;
		padding: @padding-base-vertical @padding-large-horizontal;
		a {
			color: @gray-darker;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}