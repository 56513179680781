#header {
    background: @brand-primary;
    height: @navbar-height;
    box-shadow: 0 2px 4px rgba(0,0,0,.15);
    position: fixed;
    // position: relative;
    z-index: 1025;
    width: 100%;
    left: 0;
    top: 0;
    .transition(padding .3s ease-in-out);
    &.edit {
        background: darken(@brand-primary, 4%);
    }
    @media (min-width: @screen-md-min) {
        height: @navbar-height + 8;
    }
    .ladda-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .container {
        padding-right: 6px;
    }
    .dropdown-menu {
        .pull-right;
        margin: 0;
        border: 0;
        border-radius: 0 0 2px 2px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
        @media (max-width: @screen-xs-max) {
            top: @navbar-height;
            left: auto;
            right: @grid-gutter-width / 2;
        }
    }
}

#header.edit .action {
    font-weight: 500;
    font-size: @font-size-h4;
    text-transform: uppercase;
    line-height: 32px;
    padding: 12px;
    position: relative;
    border-radius: 0;
    border: 0;
    .transition(all .3s);
    @media (min-width: @screen-md-min) {
        padding: 16px;
    }
}

#header.edit .action:hover {
    background: darken(@brand-primary, 4%);
}
#header.edit .action-submit {
    color: @white;
}
#header.edit .action-submit:hover {
    background: @brand-success;
}
#header.edit .action-cancel:hover {
    background: @brand-danger;
}

.header-inner {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
        display: inline-block;
        vertical-align: top;
        > a {
            color: #fff;
            display: block;
        }
    }
    .logo {
        font-weight: 500;
        font-size: @font-size-h4;
        text-transform: uppercase;
        line-height: 32px;
        a {
            background: url(/img/logo.svg) 0 50% no-repeat;
            padding: 12px 0 12px 40px;
            @media (min-width: @screen-md-min) {
                padding: 16px 0 16px 40px;
            }
        }
    }
}

#menu-trigger {
    color: #fff;
    display: inline-block;
    vertical-align: top;
    .text-center;
    width: 48px;
    margin-left: -16px;
    padding: 12px 0;
    .transition(opacity .3s ease-in-out);
    @media (min-width: @screen-md-min) {
        padding: 16px 0;
    }

    i {
        .transition(all .3s ease-in-out);
        border-radius: 50%;
        font-size: 22px;
        line-height: 32px;
        height: 32px;
        width: 32px;
        vertical-align: middle;
        -webkit-font-smoothing: none;
    }
    &:hover {
        i {
            .rgba-black(.1);
        }
    }
    &.open {
        i {
            .rgba-black(.1);
        }
    }
    @media (min-width: @screen-lg) {
        .opacity(0);
        width: 0;
        overflow: hidden;
        margin: 0;
    }
}



#search-close {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    .transition(all .3s ease-in-out);
    z-index: 20;
    padding: 12px 0;
    width: 56px;
    .text-center;
    position: absolute;
    right: 0;
    top: 0;
    vertical-align: middle;
    @media (min-width: @screen-md-min) {
        padding: 16px 0;
    }
    &:hover, &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h2;
        border-radius: 50%;
        padding: 0;
        line-height: 32px;
        width: 32px;
        .text-center;
    }
}
#search-trigger, #search-close {
    @media (min-width: @screen-sm-min) {
        display: none;
    }
}

.top-menu {
    .list-unstyled;
    padding: 0;
    .tools, .notifications {
        width: 100%;
        max-height: calc(~'100vh -'@navbar-height);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        left: 0;
        right: 0 !important;

        @media (min-width: @screen-sm-min) {
            left: auto;
            width: 400px;
            height: auto;
        }
    }
    .avatar {
        height: 32px;
        width: 32px;
    }
    > li {
        display: inline-block;
        vertical-align: top;
        @media (max-width: @screen-xs-max) {
            position: static;
        }
        > a {
            color: #fff;
            display: block;
            padding: 12px 2px;
            position: relative;
            @media (min-width: @screen-md-min) {
                padding: 16px 8px;
            }

            i {
                .transition(all .3s ease-in-out);
                border-radius: 50%;
                line-height: 32px;
                font-size: 24px;
                .text-center;
                height: 32px;
                width: 32px;
            }

            &:hover, &:focus {
                i {
                    .rgba-black(.1);
                }
            }
        }
        .label {
            position: absolute;
            top: 12px;
            right: 4px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            font-size: 10px;
            line-height: 20px;
            font-weight: 500;
            padding: 0;
            text-align: center;
            display: block;
        }
    }
}
.tools {
    padding: 16px;
    @media (min-width: @screen-sm-min) {
        padding: 24px;
    }
    ul {
        .clearfix;
        .list-unstyled;
    }
    ul > li, > div {
        .make-xs-column(4);
        .make-md-column(6);
        padding: 0;
        &.nieuws i { color: @orange }
        &.prikbord i { color: @orange }
        &.reservaties i { color: @red }
        &.wie-is-wie i { color: @green }
        &.interne-werking i { color: @blue }
        &.extranet i { color: @blue }
        &.tikklok i { color: @teal }
        &.fotoalbums i { color: @deeppurple }
        &.interactief i { color: @orange }
        &.inactive {
            .opacity(.3);
            -webkit-filter: grayscale(100%);
        }
        > a {
            display: block;
            padding: 16px 0;
            .text-center;
            border: 1px solid transparent;
            font-weight: 500;
            color: @gray-darker;
            > span {
                color: @gray;
                display: block;
                font-weight: 400;
                .hidden-xs;
            }
            &:hover {
                background: none;
                border-color: @gray-lighter;
                color: @gray-darker;
            }
        }
    }
    i {
        display: block;
        margin: 0 auto 10px;
        line-height: 32px;
        font-size: 40px;
    }

}
// XXSmall voor iPhone portrait
.show-xxs {
    display: none;
}
@media only screen
and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) {
    .header-inner {
        .logo {
            a  {
                background: none;
                padding: 12px 0;
            }
        }
    }
    #header .dropdown-backdrop {
        top: @navbar-height;
        background: rgba(0,0,0,.5);
    }
    .hide-xxs {
        display: none !important;
    }
    .show-xxs {
        display: block !important;
    }
    #header .notifications {
        > a:nth-of-type(1n+5) {
            display: none;
        }
    }
}