#documents {
	h1 {
		margin: 0;
	}
	@media (min-width: @screen-sm-min) {
		th, td {
			padding: 12px 16px;
			line-height: 24px;
			vertical-align: middle;
		}
		table {
			.cell-edit, .actions {
				top: 8px;
			}
		}
	}
	.cell-actions {
		width: 32px;
		padding-left: 0;
	}
	.cell-avatar {
		.text-center;
		padding-right: 0;
		vertical-align: middle;
		width: 24px;
		i {
			color: @gray;
			font-size: 24px;
			vertical-align: middle;
		}
	}
}

.card-body .table-wrap {
	border-bottom: 1px solid @gray-lighter;

		th, td {
			padding: 12px 16px;
			line-height: 24px;
			vertical-align: middle;
		}
		table {
			.cell-edit, .actions {
				top: 8px;
			}
		}
	
	.cell-actions {
		width: 32px;
		padding-left: 0;
	}
	.cell-avatar {
		.text-center;
		padding-right: 0;
		vertical-align: middle;
		width: 24px;
		i {
			color: @gray;
			font-size: 24px;
			vertical-align: top;
		}
		.zmdi-folder, .zmdi-children {
			color: #fbdf7a;
		}
		.zmdi-page, .zmdi-document {
			color: #1e90ff;
		}		
	}
	.card-footer {
		display: none;
	}
}

#document-preview, #file-preview {
	background: @gray-light;
    height:70vh;
    min-height: 600px;
    overflow: auto;
    img {
    	width: 100%;
    }
}

td.filename {
    @media (max-width: @screen-xs-max) {
        padding-right: 0 !important;
    }
    max-width: 300px;
    width: 300px;
}
td.version {
	padding-right: 0;
	width: 48px;
}