.teasers {
    margin: @line-height-computed 0;
    @media (min-width: @screen-md-min) {
        display: flex;
        flex-wrap: wrap;
    }
    a {
        background: @gray-lighter;
        display: block;
        margin-bottom: 12px;
        @media (min-width: @screen-md-min) {
            width: 32%;
            margin: 1%;
            &:nth-of-type(3n) {
                margin-right: 0;    
            }
            &:nth-of-type(3n+1) {
                margin-left: 0; 
            }
        }
        padding-bottom: 6px;
        &.has-desc {
            padding-bottom: 56px;
        }
        position: relative;
        text-decoration: none;
        .text-center;
        .transition(all .3s);
        h2 {
            font-size: @font-size-large;
            margin-top: 0;
            padding-top: 16px;
        }
        h2, p {
            .transition(all .3s);
        }
        p {
            color: @gray;
            margin: 0;   
            padding: 0 12px;
        }
        figure {
            overflow: hidden;
            height: 0;
            padding-top: 100%;
            position: relative;
            img {
                display: block;
                width: 100%;
                height: 100%;
                margin: auto;
                position: absolute;
                top: -100%;
                right: -100%;
                bottom: -100%;
                left: -100%;
            }
        }
        img {
            -webkit-backface-visibility: hidden;
            .transition(transform .3s);
        }
        .more {
            position: absolute;
            left: 0;
            bottom: 12px;
            width: 100%;
        }
        &:hover {
            background: @brand-primary;
            h2 {
                color: #fff;
            }
            p {
                color: rgba(255,255,255,.7);
            }
            .btn {
                background: #fff;
                color: @brand-primary;
            }
            img {
                transform: scale(1.1);
            }
        }
        &.disabled {
            .opacity(.3);
        }
    }
}