// Angular Medium Editor // --------------------------------------------------------------------------------------------
.angular-medium-editor {
    position: relative; // MUY IMPORTANTE!!!!
}

// Medium Editor // --------------------------------------------------------------------------------------------
.medium-editor-placeholder:after {
    color: @gray-light;
    font-style: normal;
}

.medium-insert-buttons {
    position: absolute;
    left: -30px;
    width: 30px;
    height: 30px;
}
.medium-insert-buttons-open {
    left: 0;
    width: 100%;
}

.medium-insert-active {
    margin-bottom: 220px;
}
.robin-toolbar {
    position: absolute;
    z-index: 1000;
    display: block;
    padding-left: 54px;
    -webkit-transition: background .1s ease-out;
    transition: background .1s ease-out;
    background: transparent;
    background-clip: content-box;
}
.robin-toolbar:hover {
    background: rgba(255,255,255,.3);
    background-clip: content-box;
}
.robin-edit-buttons-container {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 20px;
    line-height: 30px;
    color: #aaa;
    white-space: nowrap;
    text-align: left;
    width: 30px;
    overflow: hidden;
}
.robin-edit-buttons-content {
    display: inline-block;
    position: relative;
    z-index: 1010;
    -webkit-transition: opacity 0.1s ease-in-out, -webkit-transform .4s cubic-bezier(.19,1,.22,1);
    -moz-transition: opacity 0.1s ease-in-out, -moz-transform .4s cubic-bezier(.19,1,.22,1);
    transition: opacity 0.1s ease-in-out, transform .4s cubic-bezier(.19,1,.22,1);
    -webkit-transform: translatex(100%);
    -moz-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%);
    opacity: 0;
    pointer-events: none;
    width: 100%;
    overflow: hidden;
}
.robin-toolbar:hover .robin-edit-buttons-content {
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0);
    opacity: 1;
    pointer-events: auto;
}
.medium-insert-buttons-open .robin-add-button-content {
    position: absolute;
    left: 0px;
    top: 5px;
}

.medium-insert-buttons-open .robin-add-button {
    margin-left: 0 !important;
}

.robin-add-button,
.robin-edit-button {
    text-transform: uppercase;
    cursor: pointer;
    color: #aaa ;
    vertical-align: top;
    display: block;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #aaa;
    white-space: nowrap;
}
.robin-add-button-content {
    position: absolute;
    top: 50%;
    left: 0px;
    font-size: 20px;
    line-height: 30px;
    color: #aaa;
    white-space: nowrap;
    text-align: left;
    width: 30px;
    overflow: hidden;
    transform: translate(0, -50%);
}

.robin-add-button {
    margin-left: 30px;
}
.robin-add:hover .robin-add-button {
    margin-left: 0;
}

.robin-add-button:hover, .robin-edit-button:hover {
    // background-color: #aaa;
    color: #222;
}
.robin-add-button i {
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}
.robin-add-button.medium-insert-buttons-rotate i {
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}
.medium-insert-buttons-open .robin-add-button {
    left: -55px;
}

.robin-block-insert {
    list-style: none;
    margin: 0 0 0 54px;
    display: none;
    width: calc(~'100% - 54px');
    overflow: hidden;
}
.medium-insert-buttons-open .robin-block-insert {
    display: block;
}
.robin-block-insert li {
    float: left;
}
.robin-block-insert a {
    background: transparent;
    cursor: pointer;
    .transition(all .3s);
    font: 500 12px / 20px 'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    padding: 10px 0 20px;
    color: fade(#222, 60%);
    float: left;
    width: percentage(( 100 / 6 ) / 100);
    text-decoration: none;
    text-align: center;
    -webkit-box-sizing: initial;
    -moz-box-sizing: initial;
    box-sizing: initial;
    box-shadow: inset 0 0 1px transparent;
}
.robin-block-insert a:hover {
    background: fade(@black, 2%);
    box-shadow: inset 0 0 1px fade(@black, 20%);
    color: #222;
}
.robin-block-insert a:before {
    background: url("/img/blockicons.svg") 0 0 no-repeat;
    content: '';
    display: block;
    margin: 0 auto;
    height: 60px;
    width: 80px;
    opacity: .6;
    .transition(opacity .3s);
}
.robin-block-insert a:hover:before {
    opacity: 1;
}
//
//.robin-block-insert .blockicon-text:before {
//    background-position: 0 0;
//}
.robin-block-insert .blockicon-images:before {
    background-position: -80px 0;
}
.robin-block-insert .blockicon-galleries:before {
    background-position: -160px 0;
}
.robin-block-insert .blockicon-slideshows:before {
    background-position: -240px 0;
}
.robin-block-insert .blockicon-embeds:before,
.robin-block-insert .blockicon-video:before {
    background-position: -320px 0;
}
.robin-block-insert .blockicon-downloads:before {
    background-position: -320px -155px;
}
.robin-block-insert .blockicon-forms:before {
    background-position: 0 -80px;
}
.robin-block-insert .blockicon-maps:before {
    background-position: -80px -80px;
}
.robin-block-insert .blockicon-teaser:before {
    background-position: -160px -80px;
}
.robin-block-insert .blockicon-polls:before {
   background-position: -160px -240px;
}

//.robin-block-insert .blockicon-widget:before {
//    background-position: -240px -80px;
//}
.robin-block-insert .blockicon-buttons:before {
    background-position: -320px -80px;
}
//.robin-block-insert .blockicon-2-col:before {
//    background-position: 0 -160px;
//}
//.robin-block-insert .blockicon-2-col_1-3_2-3:before {
//    background-position: -240px -160px;
//}
//.robin-block-insert .blockicon-2-col_2-3_1-3:before {
//    background-position: -160px -160px;
//}
//.robin-block-insert .blockicon-3-col:before {
//    background-position: -80px -160px;
//}
//.robin-block-insert .blockicon-4-col:before {
//    background-position: 0 -240px;
//}

.robin-block-insert .blockicon-cta:before {
   background-position: -80px -240px;
}

.medium-insert-embeds .medium-insert-embeds-overlay, .mediumInsert-embeds .medium-insert-embeds-overlay {
    position: absolute;
    /* width: 100%; */
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin-left: -54px;
}

.medium-editor-dummy {
    width: 100%;
}
.medium-editor-dummy.medium-insert-active {
    display: inline-block;
}

.medium-editor-action .zmdi {
    font-size: 20px;
}
.medium-editor-action strong {
    font-weight: 800;
}

robin-image,
robin-gallery,
robin-slideshow,
robin-cta,
robin-teaser,
robin-button,
robin-form,
robin-embed {
    background: fade(@gray-lighter, 30%);
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100px;
}

robin-embed {
    height: 350px;
}

robin-image:after,
robin-gallery:after,
robin-slideshow:after,
robin-cta:after,
robin-teaser:after,
robin-button:after,
robin-form:after,
robin-embed:after {
    content: "";
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fade(@gray-lighter, 60%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

robin-link:after {
    content: attr(label);
    color: @link-color;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}