.table-responsive  {
    border: 0;
    margin: 0;
    &+.card-footer {
        border-top: 1px solid @gray-lighter;
        padding: 12px 16px;
    }
}
.table {
    .table-hover;
    margin: 0;
    width: 100%;
    &.vam {
        td {
            vertical-align: middle;
        }
    }
}
.table>tbody>tr>th {
     color: @gray-dark;
     border-top: 0;
     text-transform: uppercase;
     padding: 12px 16px;
}

.table>thead>tr>th {
     color: @gray-dark;
     border-color: @gray-lighter;
     border-width: 1px;
     padding: 16px;
     line-height: 24px;
}
.table>tbody >tr>td {
    vertical-align: top;
    &.cell-avatar {
        padding-right: 0;
    }
}
.cell-avatar {
    width: 40px;
    white-space: nowrap;
}
.cell-text {
    // max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.singleline, &.filename {
        vertical-align: middle;
    }
}
.cell-actions {
    position: relative;
    width: 32px;
    text-align: right;
    white-space: nowrap;
}

tr {
    &.disabled {
        .opacity(.3);
    }
}

td {
    a {
        color: @gray-darker;
        font-weight: 500;
        cursor: pointer;
        display: inline-block;
        &:hover {
            color: @gray-darker;
        }
        //i {
        //    font-size: @font-size-h2 !important;
        //    position: relative;
        //    left: 1px;
        //    vertical-align: middle;
        //}
    }
    .avatar {
      .img-circle;
    }

    .tooltip {
        white-space: nowrap;
    }
}

.cell-edit {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    text-align: center;
    position: relative;
    .transition(background .3s);
    z-index: 3;
    &:hover {
        > i {
            color: @gray-darker;
        }
    }
    > i {
        .transition(color .3s);
        vertical-align: middle;
        color: @gray-light;
        font-size: @font-size-h2 - 2;
        line-height: 32px;
        }
    }


.row--inactive,
.row--inactive a {
    color: @gray-light;
}

#users-table .row--inactive {
    opacity: 0.7;
}

.row--inactive {
    img {
        filter: gray;
        filter: grayscale(100%);
        .opacity(.5);
    }
}
tr {
    .cell-edit {
        position: absolute;
        top: 16px;
        right: 40px;
        .opacity(0);
    }
    &:hover {
        .cell-edit {
            .opacity(1);
        }
    }
}

.icon-avatar {
    background: @gray-light;
    color: #fff;
    border-radius: 50%;
    line-height: 50px;
    height: 40px;
    width: 40px;
    .text-center;
    i {
        font-size: 30px;
    }
}
.table-search {
    padding: 12px 16px 0;
}
.th-sidebar {
    width: 30%;
    min-width: 220px;
}

.th-overflow {
    width: 10%;
    min-width: 48px;
    .text-center;
    span {
        cursor: pointer;
        width: 48px;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        white-space: nowrap;
    }
}
