// flexible it is not
// Interne organisatie
.dept-interne-organisatie #header, .dept-interne-organisatie #search {
    background: @amber;
}
li.dept-interne-organisatie {
    i {  color: @amber; display: block; }
}
.dept-interne-organisatie {
    .card-dept i, .card-icon i { background: @amber; display: block; }
    &.avatar-holder i { color: @amber; display: block; }
}
// Vrije tijd
.dept-vrije-tijd #header, .dept-vrije-tijd #search {
    background: @cyan;
}
li.dept-vrije-tijd {
    i {  color: @cyan; display: block; }
}
.dept-vrije-tijd {
    .card-dept i, .card-icon i { background: @cyan; display: block; }
    &.avatar-holder i { color: @cyan; display: block; }
}
// Burgerzaken
.dept-burgerzaken #header, .dept-burgerzaken #search {
    background: @orange;
}
li.dept-burgerzaken {
    i {  color: @orange; display: block; }
}
.dept-burgerzaken {
    .card-dept i, .card-icon i { background: @orange; display: block; }
    &.avatar-holder i { color: @orange; display: block; }
}
// Grondgebiedzaken
.dept-grondgebiedzaken #header, .dept-grondgebiedzaken #search  {
    background: @lightgreen;
}
li.dept-grondgebiedzaken {
    i {  color: @lightgreen; display: block; }
}
.dept-grondgebiedzaken {
    .card-dept i, .card-icon i { background: @lightgreen; display: block; }
    &.avatar-holder i { color: @lightgreen; display: block; }
}
// Technische dienst
.dept-technische-dienst #header, .dept-technische-dienst #search {
    background: @pink;
}
li.dept-technische-dienst {
    i {  color: @pink; display: block; }
}
.dept-technische-dienst {
    .card-dept i, .card-icon i { background: @pink; }
    &.avatar-holder i { color: @pink; display: block; }
}
// Financiën
.dept-financiele-dienst #header, .dept-financiele-dienst #search {
    background: @purple;
}
li.dept-financiele-dienst {
    i {  color: @purple; display: block; }
}
.dept-financiele-dienst {
    .card-dept i, .card-icon i { background: @purple; display: block; }
    &.avatar-holder i { color: @deeppurple; display: block; }
}
// Sociaal Huis
.dept-sociaal-huis #header, .dept-sociaal-huis #search {
    background: @lime;
}
li.dept-sociaal-huis {
    i {  color: @lime; display: block; }
}
.dept-sociaal-huis {
    .card-dept i, .card-icon i { background: @lime; display: block; }
    &.avatar-holder i { color: @lime; display: block; }
}
// Onthaal
.dept-onthaal #header, .dept-onthaal #search {
    background: @yellow;
}
li.dept-onthaal {
    i {  color: @yellow; display: block; }
}
.dept-onthaal {
    .card-dept i, .card-icon i { background: @yellow; display: block; }
    &.avatar-holder i { color: @yellow; display: block; }
}

// Poetsdienst
.dept-poetsdienst #header, .dept-poetsdienst #search {
    background: @red;
}
li.dept-poetsdienst {
    i {  color: @red; display: block; }
}
.dept-poetsdienst {
    .card-dept i, .card-icon i { background: @red; }
    &.avatar-holder i { color: @red; display: block; }
}

// Werklieden dienst
.dept-werkliedendienst #header, .dept-werkliedendienst #search {
    background: @red;
}
li.dept-werkliedendienst {
    i {  color: @red; display: block; }
}
.dept-werkliedendienst {
    .card-dept i, .card-icon i { background: @red; }
    &.avatar-holder i { color: @red; display: block; }
}

// AGB
.dept-agb #header, .dept-agb #search {
    background: @cyan;
}
li.dept-agb {
    i {  color: @cyan; display: block; }
}
.dept-agb {
    .card-dept i, .card-icon i { background: @cyan; }
    &.avatar-holder i { color: @cyan; display: block; }
}