#main {
    padding-top: @navbar-height;
    @media (min-width: @screen-md-min) {
        padding-top: @navbar-height + 8;
    }
}

#content {
    .transition(all .3s ease-in-out);
    padding: (@grid-gutter-width / 2) 0 @grid-gutter-width;
    @media (min-width: @screen-lg) {
        padding-left: @navbar-width;
    }
    &.without-sidebar {
        padding-left: 0;
    }
    > .container {
        max-width: @screen-lg;
        @media (max-width: @screen-xs-max) {
            overflow: hidden;
            padding: 0 0 8px;
        }
    }
}

.container {
    @media (min-width: @screen-sm-min) {
        width: calc(~'100% - 30px');
    }
}
.col-2 {
    .make-sm-column(6);
}

.col-3, .col-1-3 {
    .make-sm-column(4);
}
.col-2-3 {
    .make-sm-column(8);
}
.col-9 {
    .make-sm-column(8);
}


#main-header {
    background: @brand-primary;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    .clearfix;
    padding: 15px 0;
    z-index: 10;
    .transition(padding-left .3s ease-in-out);
    @media (min-width: @screen-sm-min) {
        margin-bottom: @grid-gutter-width;
    }
    @media (min-width: @screen-lg) {
        padding-left: @navbar-width;
    }
    > .container {
        max-width: @screen-md;
    }
    .container {
        padding-right: 10px;
    }
    h1 {
        display: inline-block;
        font-weight: 400;
        color: #fff;
        margin: 0;
        line-height: @line-height-computed * 2;
        white-space: nowrap;
        overflow: hidden;
        height: @line-height-computed * 2;
        text-overflow: ellipsis;
        vertical-align: top;
        width: calc(~'100% - 45px');
        position: relative;
        z-index: 10;
        @media (min-width: @screen-lg) {
            width: calc(~'100% - 30px');
        }
        a {
            color: #fff;
            .transition(all .3s ease-in-out);

            &:hover {
                box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .5);
            }
        }
    }
    .chapter {
        display: inline-block;
        height: @line-height-computed * 2;
        &:before {
            .icon;
            content: "\f29c";
            font-size: 24px;
            height: @grid-gutter-width;
            width: 24px;
            position: relative;
            top: 5px;
            margin-right: 5px;
        }
    }

    .btn-toolbar {
        .pull-right;
        position: relative;
        z-index: 20;
    }
    .btn {
        .btn-success;
    }
}


.header-actions {
    .pull-right;
    height: 40px;
    margin-top: -40px;
    i {
        .transition(all .3s ease-in-out);
        border-radius: 50%;
        line-height: 30px;
        font-size: 20px;
        .text-center;
        height: 30px;
        width: 30px;
    }
    > a {
        cursor: pointer;
        display: inline-block;
        color: #fff;
        padding: 5px 0;
        position: relative;
        z-index: 15;
        &:hover, &:focus {
            i {
                .rgba-black(.1);
            }
        }
      }
    }

#empty-state {
    margin: 0 auto;
    max-width: @screen-xs;
    .text-center;
    i {
        color: darken(@gray-light, 10%);
    }
}

.cta {
    margin: @line-height-computed 0;
}

.cta-container {
    background: @brand-primary;
    padding: 24px;
    .text-center;
    .clearfix;
    @media (min-width: @screen-sm-min) {
        display: table;
        text-align: left;
        position: relative;
        width: 100%;
    }
    h2 {
        margin-top: 0;
    }
    h2, p {
        color: #fff;
    }
     .cta-btn {
        margin-top: @line-height-computed;
     }
    @media (min-width: @screen-sm-min) {
        .cta-text, .cta-btn {
            display: table-cell;
            vertical-align: middle;
        }
        .cta-btn {
            text-align: right;
            padding-left: @grid-gutter-width;
            margin: 0;
        }
    }
}