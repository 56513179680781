
#sidebar {
    position: fixed;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    top: @navbar-height;
    // top: 0;
    height: calc(~'100%' - @navbar-height);
    // height: 100%;
    // height: 100vh;
    -webkit-transform: translate3d(-@navbar-width,0,0);
    transform: translate3d(-@navbar-width,0,0);
    z-index: 25;
    width: @navbar-width;
    overflow-x: hidden;
    .opacity(0);
    .transition(all .3s);
    font-weight: 500;
    &.toggled {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      .opacity(1);
    }
    @media (min-width: @screen-md-min) {
        top: @navbar-height + 8;
        height: calc(~'100%' - (@navbar-height + 8));
    }

    @media (min-width: @screen-lg) {
        -webkit-transform: translate3d(0,0,0) !important;
        transform: translate3d(0,0,0) !important;
        .opacity(1);
        // box-shadow: 0 1px 1px rgba(0,0,0,.15);
    }

    .sidebar-inner {
        height: 100%;
        overflow-y: auto;
    }
}

#sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    will-change: opacity;
    @media (min-width: @screen-lg) {
        display: none;
    }
}

.main-menu {
    margin: 0;
    .nav;
    li:first-child {
        border-bottom: 1px solid @gray-lighter;
        i {
            color: @yellow;
        } 
    }
    a {
        color: @gray-darker;
        padding: 12px 16px 12px 56px !important;
        &:hover {
            background: @gray-lightest !important;
            color: @gray-darker;
        }
    }
    .active a {
        background: @gray-lighter;
        color: @gray-darker;
    }
    .sub-menu {
        > a {
            &:before {
                .icon;
                content: "\f0fb";
                position: absolute;
                font-size: @font-size-h2;
                line-height: 1;
                right: 15px;
                top: 12px;
            }
        }
        &.toggled {
            >  a {
                &:before {
                    content: "\f111";
                }
            }
        }

        ul {
            .nav;
            background: @gray-lighter;
            padding: 0;
            display: none;
            a {
                color: lighten(@gray, 10%);
                &:hover {
                    color: @gray-darker;
                }
            }
        }
    }
    i {
        color: @gray;
        font-size: @font-size-large;
        line-height: 1;
        min-width: 30px;
        position: absolute;
        left: 24px;
        top: 13px;
    }
}

.main-menu .heading {
    color: @gray;
    padding: 12px 15px 12px 24px !important;
}

.profile-menu {
    height: 120px;
    background: darken(@brand-primary, 4%);

    .avatar {
        margin: 15px;
        border-radius: 50%;
        position: relative;
        z-index: 50;
    }

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        z-index: 40;
        background: url(../img/profile-bg.jpg) 0 0 no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 100%;
        .opacity(.2);
    }

    > a {
        color: #fff;
        display: block;
        padding: 10px 15px;
        position: relative;
        .transition(background .3s ease-in-out);
        z-index: 50;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
        &:before {
            .icon;
            content: "\f29e";
            font-size: @font-size-h1;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
        &:hover {
           background: rgba(0,0,0,.15);
        }
    }
}